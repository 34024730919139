<div class="footer" [class.asOverlay]="justOverlay" *ngIf="!hidden">
  <div>
    <ng-container *ngIf="!isEmbed && !justOverlay">
      <span class="copyright">2024 - {{ lawFirmName }}</span>
      <span class="important-links">
        <a [href]="imprintLink" target="_blank" *ngIf="imprintLink">{{
          "common.label.imprint" | translate
          }}</a>
        <a [href]="dseLink" target="_blank" *ngIf="dseLink">{{
          "common.label.dse" | translate
          }}</a>
      </span>
    </ng-container>
  </div>
  <div class="language-select" [matMenuTriggerFor]="languageMenu" *ngIf="!auth?.loggedIn || !auth.isClient">
    <span [ngClass]="[
        'fi',
        'fi-' +
          (translator.currentLang === 'en' ? 'gb' : translator.currentLang)
      ]"></span><span>{{ translator.currentLang | uppercase }}</span>
    <mat-icon>expand_less</mat-icon>
  </div>
  <mat-menu #languageMenu="matMenu">
    <ng-container *ngFor="let language of languages">
      <button mat-menu-item *ngIf="language !== translator.currentLang" (click)="selectLanguage(language)">
        <span [ngClass]="['fi', 'fi-' + (language === 'en' ? 'gb' : language)]"></span>
        {{ language | uppercase }}
      </button>
    </ng-container>
  </mat-menu>
  <div>
    <div class="poweredby" *ngIf="customLogo">
      <img src="assets/LogoIcon@1.svg" />
      <img src="assets/Logo.svg" />
    </div>
  </div>
</div>
